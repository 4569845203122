<template>
  <div class="flex flex-row">
    <nav v-if="items">
      <ul class="justify-start flex flex-row m-0 p-0" v-show="pagesNumber.length > 1">
        <li @click="first" v-if="items.current_page > 1"
            class="px-2 py-0 bg-primary-800 text-white rounded-l flex flex-row items-center hover:bg-primary-950 cursor-pointer">
          <i class="far fa-chevron-double-left"></i>
        </li>
        <li @click="previous(items.current_page)"
            class="px-2 py-0 border-solid border-y-0 border-r-0 border-l-2 border-l-gray-50 bg-primary-800 text-white flex flex-row items-center hover:bg-primary-950 cursor-pointer"
            v-if="items.current_page > 1">
          <i class="far fa-chevron-left"></i>
        </li>
        <li
          class="first:rounded-l last:rounded-r border-solid border-l-0 first:border-l-2 px-2 py-0 border-r-2 border-y-2 border-primary-800 flex flex-row items-center hover:bg-primary-700 cursor-pointer hover:text-white"
          v-for="pag in pagesNumber" :class="[pag == page ? 'bg-primary-800 text-white' : 'text-primary-800']"
          @click="cambiarPagina(pag)">
          <template v-if="pag == page && loading">
            <i class="fa fa-cog animate-spin"></i>
          </template>
          <template v-else>
            {{ pag }}
          </template>
        </li>
        <li @click="next(items.current_page)"
            class="px-2 py-0 border-solid border-y-0 border-l-0 border-r-2 border-r-gray-50 bg-primary-800 text-white flex flex-row items-center hover:bg-primary-950 cursor-pointer"
            v-if="items.current_page < items.last_page">
          <i class="far fa-chevron-right"></i>
        </li>
        <li @click="last"
            class="px-2 py-0 bg-primary-800 text-white rounded-r flex flex-row items-center hover:bg-primary-950 cursor-pointer"
            v-if="items.current_page < items.last_page">
          <i class="far fa-chevron-double-right"></i>
        </li>
      </ul>
    </nav>
    <div class="flex flex-grow justify-end items-center text-sm pr-2" v-if="total>0">
      <span><b>{{ total + (total === 1 ? ' Resultado' : ' Resultados') }}</b></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: String,
    paginate: {
      default: true
    }
  },
  data: function () {
    return {
      offset: 4,
      pagesArray: [],
      items: [],
      extra_data: null,
      filtros: {},
      page: 1,
      total: 0,
      loading: false,
    }
  },
  computed: {
    isActived: function () {
      return this.items.current_page
    },
    pagesNumber: function () {
      if (!this.items.to) {
        return [];
      }

      var from = this.items.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.items.last_page) {
        to = this.items.last_page;
      }
      this.pagesArray = [];
      while (from <= to) {
        this.pagesArray.push(from);
        from++;
      }
      return this.pagesArray;
    }
  },
  methods: {
    cambiarPagina: function (page) {
      this.page = page;
      this.consultar(this.filtros, page);
    },
    previous: function (page) {
      this.page = page - 1;
      this.consultar(this.filtros, this.page);
    },
    next: function (page) {
      this.page = page + 1;
      this.consultar(this.filtros, this.page);
    },
    last: function () {
      this.page = this.items.last_page;
      this.consultar(this.filtros, this.page);
    },
    first: function () {
      this.page = 1;
      this.consultar(this.filtros, this.page);
    },
    consultar: function (filtros, page) {
      var c_vm = this;
      filtros.paginate = !!c_vm.paginate;
      c_vm.filtros = filtros;
      this.loading = true;
      axios.get(this.url, {
        params: {
          page: page,
          campos: c_vm.filtros,
        }
      })
        .then((response) => {
          console.log(response);
          this.loading = false
          c_vm.items = response.data.data.items;
          c_vm.total = response.data.data.total;
          c_vm.extra_data = response.data.data.data;
          c_vm.$emit('loaded', c_vm.items, c_vm.extra_data);
        });
      }
  }
}
</script>
