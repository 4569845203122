<template>
  <div>
    <div style="min-width: 200px; max-width: 200px;" class="bg-primary-950 p-2 h-full relative" v-show="show_menu">
      <div class="absolute top-1 left-1 text-xs text-primary-200">
        {{ version }}
      </div>
      <div class="absolute right-1 top-1">
        <button
          class="bg-primary-900 border-0 text-white hover:bg-neutral-100 hover:text-black px-2 rounded transition-all"
          @click="show_menu = false">
          <i class="fa fa-chevron-left"></i>
        </button>
      </div>
      <div class="d-flex flex-column h-100">
        <div class="flex flex-row justify-center">
          <div class="p-3" v-if="$theme === 'csa'">
            <div class="bg-neutral-200 p-2 rounded">
              <img src="../../assets/csa.svg" class="w-100">
            </div>
          </div>
          <div class="bg-primary-950 rounded mt-2" v-else-if="$theme === 'sara'">
            <img src="../../assets/logo_sara.png" class="max-h-24">
          </div>
        </div>

        <div class="grow flex flex-col gap-1 overflow-auto text-center">
          <template v-if="$permisos.some(lista_administracion)">
            <div
              class="cursor-pointer flex items-center justify-center bg-primary-900 hover:bg-primary-800 rounded p-1 text-white"
              @click="show_administracion = !show_administracion">
              <b class="flex-grow-1 text-sm mb-0">Administración</b>
              <i class="fa fa-chevron-down mb-0 me-3" v-if="show_administracion"></i>
              <i class="fa fa-chevron-up mb-0 me-3" v-else></i>
            </div>
            <div v-if="show_administracion"
                 class="rounded-b border-t border-solid border-b-0 border-x-0 border-primary-950 flex flex-col gap-1">
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/catalogos"
                v-if="$permisos.some(lista_catalogos)">
                Catálogos
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/reportes"
                v-if="$permisos.can('reportes.menu')">
                Reportes
              </router-link>
            </div>
          </template>

          <template v-if="$permisos.some(lista_funciones)">
            <div
              class="cursor-pointer flex items-center justify-center bg-primary-900 hover:bg-primary-800 rounded p-1 text-white"
              @click="show_operacion = !show_operacion">
              <b class="flex-grow-1 text-sm mb-0">Operación</b>
              <i class="fa fa-chevron-down mb-0 me-3" v-if="show_operacion"></i>
              <i class="fa fa-chevron-up mb-0 me-3" v-else></i>
            </div>
            <div v-show="show_operacion"
                 class="rounded-b border-t border-solid border-b-0 border-x-0 border-primary-950 flex flex-col gap-1">
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/pedidos"
                v-if="$permisos.can('pedidos.menu')">
                Nuevo pedido
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/cajasop"
                v-if="$permisos.can('cajas_op.menu')">
                Cajas
              </router-link>
            </div>
          </template>

          <template v-if="$permisos.some(lista_inventario)">
            <div
              class="cursor-pointer flex items-center justify-center bg-primary-900 hover:bg-primary-800 rounded p-1 text-white"
              @click="show_inventario = !show_inventario">
              <b class="flex-grow-1 text-sm mb-0">Inventario</b>
              <i class="fa fa-chevron-down mb-0 me-3" v-if="show_inventario"></i>
              <i class="fa fa-chevron-up mb-0 me-3" v-else></i>
            </div>
            <div v-show="show_inventario"
                 class="rounded-b border-t border-solid border-b-0 border-x-0 border-primary-950 flex flex-col gap-1">
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/proveedores"
                v-if="$permisos.can('movimiento_almacen.menu')">
                Proveedores
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/movimiento_almacen/entrada"
                v-if="$permisos.can('movimiento_almacen.menu')">
                Entrada
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/movimiento_almacen/salida"
                v-if="$permisos.can('movimiento_almacen.menu')">
                Salida
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/movimiento_almacen"
                v-if="$permisos.can('movimiento_almacen.menu')">
                Movimientos
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/existencias"
                v-if="$permisos.can('movimiento_almacen.menu')">
                Existencias
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/transformacion"
                v-if="$permisos.can('preceso_transformacion.menu')">
                Transformación
              </router-link>
            </div>
          </template>

          <template v-if="$permisos.some(lista_edo_cuentas)">
            <div
              class="cursor-pointer flex items-center justify-center bg-primary-900 hover:bg-primary-800 rounded p-1 text-white"
              @click="show_estados_cuenta = !show_estados_cuenta">
              <b class="flex-grow-1 text-sm mb-0">Estados de cuenta</b>
              <i class="fa fa-chevron-down mb-0 me-3" v-if="show_estados_cuenta"></i>
              <i class="fa fa-chevron-up mb-0 me-3" v-else></i>
            </div>
            <div v-show="show_estados_cuenta"
                 class="rounded-b border-t border-solid border-b-0 border-x-0 border-primary-950 flex flex-col gap-1">
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/clientes"
                v-if="$permisos.can('clientes.menu')">
                Clientes
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/repartidores"
                v-if="$permisos.can('repartidores.menu')">
                Repartidores
              </router-link>
            </div>
          </template>

          <template v-if="$permisos.some(lista_monitoreo)">
            <div
              class="cursor-pointer flex items-center justify-center bg-primary-900 hover:bg-primary-800 rounded p-1 text-white"
              @click="show_monitoreo = !show_monitoreo">
              <b class="flex-grow-1 text-sm mb-0">Monitoreo</b>
              <i class="fa fa-chevron-down mb-0 me-3" v-if="show_monitoreo"></i>
              <i class="fa fa-chevron-up mb-0 me-3" v-else></i>
            </div>
            <div v-show="show_monitoreo"
                 class="rounded-b border-t border-solid border-b-0 border-x-0 border-primary-950 flex flex-col gap-1">
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/monitorPedidos"
                v-if="$permisos.can('pedidos.menu')">
                Monitor
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/depositosGeneral"
                v-if="$permisos.can('depositos_general.menu')">
                Depósitos en general
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/estatusPedidos"
                v-if="$permisos.can('pedidos.menu')">
                Estatus de pedidos
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/cobranzaPedidos"
                v-if="$permisos.can('pedidos_cobranza.menu')">
                Cobranza de pedidos
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/monitorRastreo"
                v-if="$permisos.can('pedidos.menu')">
                Rastreo de unidades
              </router-link>
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/estatusVentas"
                v-if="$permisos.can('ventas.menu')">
                Ventas
              </router-link>
            </div>
          </template>
          <template v-if="$permisos.some(lista_monitoreo)">
            <div
              class="cursor-pointer flex items-center justify-center bg-primary-900 hover:bg-primary-800 rounded p-1 text-white"
              @click="show_contenido = !show_contenido">
              <b class="flex-grow-1 text-sm mb-0">Contenido</b>
              <i class="fa fa-chevron-down mb-0 me-3" v-if="show_contenido"></i>
              <i class="fa fa-chevron-up mb-0 me-3" v-else></i>
            </div>
            <div v-show="show_contenido"
                 class="rounded-b border-t border-solid border-b-0 border-x-0 border-primary-950 flex flex-col gap-1">
              <router-link
                class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                to="/ContenidoMonitores"
                v-if="$permisos.can('pedidos.menu')">
                Monitores
              </router-link>
              <router-link to="/ContenidoRepositorio"
                           class="block text-white no-underline hover:font-bold hover:bg-primary-200 hover:text-primary-950 mx-2 rounded leading-none py-1"
                           v-if="$permisos.can('pedidos.menu')">
                Repositorio
              </router-link>
            </div>
          </template>
        </div>
        <div class=" my-2
            ">
          <div class="flex flex-row">
            <div class="grow flex flex-row gap-1">
              <router-link class="btn btn-sm btn-outline-light" to="/configuraciones"
                           v-if="$permisos.can('configuraciones.menu')">
                <i class="fa fa-cog"></i>
              </router-link>
              <router-link to="/mi_cuenta" class="btn btn-sm btn-outline-light"
                           v-tooltip.top="data.full_name">
                <i class="fa fa-user-circle"></i>
              </router-link>
            </div>
            <div>
              <button @click="logout" class="btn btn-sm btn-outline-light"
                      v-tooltip.top="'Cerrar sesión'">
                <i class="fa fa-sign-out"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="px-3 py-2 ">
          <img src="../../assets/dev_by_white.png" class="w-100">
        </div>
      </div>
    </div>
    <div style="min-width: 55px; max-width: 55px;" class="bg-primary h-100 p-2" v-show="!show_menu">
      <div class="d-flex flex-column h-100">
        <div class="bg-light rounded p-1" v-if="$theme === 'csa'">
          <img src="../../assets/csa.svg" class="w-100">
        </div>
        <div class="bg-light rounded" v-else-if="$theme === 'sara'">
          <img src="../../assets/logo_sara.png" class="w-100">
        </div>
        <div class="flex-grow-1 d-flex align-items-center justify-content-end">
          <button class="btn btn-outline-light btn-sm float-end mb-2" style="height: fit-content !important;"
                  @click="show_menu = true">
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.text-menu-title {
  color: #bbb !important;
}
</style>
<script setup>
import axios from 'axios';
import {useUserStore} from '../../stores/user';
import {VERSION} from '../../code/app';
import {useStorage} from "@vueuse/core";
import {useRouter} from "vue-router";

const userStore = useUserStore();
const router = useRouter();

const data = userStore.data;
const version = VERSION;
const show_menu = useStorage('sara.show_menu', true);
const show_operacion = useStorage('sara.operacion', true);
const show_inventario = useStorage('sara.inventarios', true);
const show_administracion = useStorage('sara.administracion', true);
const show_estados_cuenta = useStorage('sara.estados_cuenta', true);
const show_monitoreo = useStorage('sara.monitoreo', true);
const show_contenido = useStorage('sara.contenido', true);

const lista_catalogos = [
  'cajas.menu',
  'categorias.menu',
  'estaciones.menu',
  'listasPrecios.menu',
  'motivosDevolucion.menu',
  'productos.menu',
  'unidadesMedida.menu',
  'usuarios.menu',
];

const lista_funciones = [
  'pedidos.menu',
  'cajas_op.menu',
];

const lista_inventario = [
  'movimiento_almacen.menu',
];

const lista_administracion = [
  'cajas.menu',
  'categorias.menu',
  'estaciones.menu',
  'listasPrecios.menu',
  'motivosDevolucion.menu',
  'productos.menu',
  'unidadesMedida.menu',
  'usuarios.menu',
  'reportes.menu',
];

const lista_edo_cuentas = [
  'clientes.menu',
  'repartidores.menu',
];

const lista_monitoreo = [
  'pedidos.menu',
];

const logout = async () => {
  await axios.post('/logout');
  userStore.data = null;
  userStore.is_logged_in = null;
  await router.replace('/login');
};

</script>
