<template>
  <div class="z-[10000]">
    <transition name="slide-fade">
      <div v-if="is_showing" :class="['alert', 'flash-alerts', 'p-0', color]" :key="id" style="z-index: 1102;">
        <div class="d-flex flex-row h-100">
          <div class="flex-grow-1 py-2 px-3">
            {{ localMessage }}
          </div>
          <div v-if="dismissable" class="p-1">
            <div class="border border-success h-100 d-flex align-items-end" style="width: 5px;">
              <div class="w-100 bg-success" :style="{height: timer_remain_percent + '%'}"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'FlashAlert',
  data() {
    return {
      localMessage: null,
      color: '',
      id: 1,
      timer_remain_percent: 0,
      timer: null,
      timer_counter: 0,
      auto_dismiss: true,
      is_showing: false,
      dismissable: true,
    }
  },
  methods: {
    showMessage(msg, timeout) {
      this.id++;
      this.is_showing = true;
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (msg === undefined || msg === null) {
        this.localMessage = null;
      } else {
        this.localMessage = msg;
      }

      if (this.auto_dismiss) {
        this.timer_counter = timeout;
        this.timer = setInterval(() => {
          this.timer_counter = this.timer_counter - 50;
          this.timer_remain_percent = this.timer_counter * 100 / timeout;
          if (this.timer_counter <= 0) {
            clearInterval(this.timer);
            this.is_showing = false;
          }
        }, 50);
      }

    },
  },
  created() {
    this.emitter.on('messages.show_error', (msg, timeout = 4000) => {
      this.color = 'alert-danger';
      this.showMessage(msg, timeout);
    });
    this.emitter.on('messages.show_warning', (msg, timeout = 4000) => {
      this.color = 'alert-warning';
      this.showMessage(msg, timeout);
    });
    this.emitter.on('messages.show_success', (msg, timeout = 4000) => {
      this.color = 'alert-success';
      this.showMessage(msg, timeout);
    });
  },
}
</script>

<style scoped>

.flash-alerts {
  min-width: 300px;
  max-width: 80%;
  z-index: 1000;
  bottom: 20px;
  position: fixed;
  left: 60%;
  transform: translateX(-60%);
}

.flash-alerts .alert {
  padding: 20px 40px;
  text-align: center;
}

.alert-error {
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  top: 54px;
  z-index: 10;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateY(100px);
  opacity: 0;
}
</style>
