import {createApp, provide} from 'vue'
import {createPinia} from 'pinia'
import mitt from 'mitt'
import {pusher} from "./pusher/index"
import {DateTime} from "luxon"
import VueGoogleMaps from 'vue-google-maps-community-fork'
import {VERSION} from "./code/app";
import "./styles/app.scss"
import "./styles/tailwind.css"

const emitter = mitt();
const theme = import.meta.env.VITE_THEME || 'csa';

import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from './router'
import axios from "axios";
import FormError from "./components/utils/FormError.vue";
import Paginador from "./components/utils/Paginador.vue";
import FlashAlert from "./components/utils/FlashAlert.vue";
import DialogoCargando from "./components/utils/DialogoCargando.vue";
import LabelTextCounter from "./components/utils/LabelTextCounter.vue";
import permisos from './permisos'
import configuraciones from './code/configuraciones.js'

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.baseURL = import.meta.env.VITE_SERVER_URL;
axios.defaults.headers.common['Accept'] = 'application/json';

axios.get('/sanctum/csrf-cookie');

window.axios = axios;

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://f139da63d53ee8514c6ec9c7d6153974@sentry.tresfactorial.com/3",
  integrations: [
    // Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  release: VERSION,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.config.globalProperties.$theme = theme;
app.config.globalProperties.$permisos = permisos;
app.config.globalProperties.$configuraciones = configuraciones;
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$pusher = pusher;
app.provide('$pusher', pusher);
app.provide('emitter', emitter);
app.provide('theme', theme);

app.config.globalProperties.$formatoNumero = (numero, formato = 'es-MX', decimals = 2) => {
  let numeroFormateado = 0;
  if (numero && numero !== '' && numero !== null && numero !== undefined && !isNaN(numero)) {
    numeroFormateado = Intl.NumberFormat('es-MX', {
      maximumFractionDigits: decimals,
    }).format(numero);
  }
  return numeroFormateado;
};
app.config.globalProperties.$textSubstring = (string, length = 40, showSuspensive = true) => {
  if (string) {
    if (string.length > length) {
      return string.substring(0, length) + (showSuspensive ? '...' : '')
    } else {
      return string
    }
  } else {
    return string;
  }
};
app.config.globalProperties.$formatearFecha = (value, from = 'sql', to = 'dd/MM/yyyy') => {
  if (!value) {
    return '-';
  }
  let fecha;
  if (from === 'sql') {
    fecha = DateTime.fromSQL(value);
  } else if (from === 'iso') {
    fecha = DateTime.fromISO(value);
  } else if (from === 'millis') {
    fecha = DateTime.fromMillis(value);
  } else if (from === 'luxon') {
    fecha = value;
  }
  if (!fecha?.isValid) {
    return '-';
  }
  return fecha.toFormat(to);
};

app.component('paginador', Paginador);
app.component('form-error', FormError);
app.component('flash-alert', FlashAlert);
app.component('dialogo-cargando', DialogoCargando);
app.component('text-counter', LabelTextCounter);

app.use(PrimeVue, {
  locale: {
    startsWith: 'Empieza con',
    contains: 'Contiene',
    notContains: 'No contiene',
    endsWith: 'Termina con',
    equals: 'Es igual',
    notEquals: 'No es igual',
    noFilter: 'Sin filtro',
    lt: 'Menos a',
    lte: 'Menos o igual a',
    gt: 'Mayor a',
    gte: 'Mayor o igual a',
    dateIs: 'La fecha es',
    dateIsNot: 'La fecha no es',
    dateBefore: 'La fecha es antes de',
    dateAfter: 'La fecha es después de',
    clear: 'Limpiar',
    apply: 'Aplicar',
    matchAll: 'Todo coincide',
    matchAny: 'Cualquier parte coincide',
    addRule: 'Agregar regla',
    removeRule: 'Quitar regla',
    accept: 'Aceptar',
    reject: 'Rechazar',
    choose: 'Escoger',
    upload: 'Subir',
    cancel: 'Cancelar',
    dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mier", "Juev", "Vier", "Sab"],
    dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    today: 'Hoy',
    weekHeader: 'Sem',
    firstDayOfWeek: 0,
    dateFormat: 'dd/mm/yy',
    weak: 'Semana',
    medium: 'Medio',
    strong: 'Fuerte',
    passwordPrompt: 'Ingrese la contraseña',
    emptyFilterMessage: 'No hay opciones disponibles',
    emptyMessage: 'No hay opciones disponibles'
  }
});
app.use(createPinia())
app.use(router)
app.use(VueGoogleMaps, {
  load: {
    key: import.meta.env.VITE_GOOGLE_API_KEY
  }
})

app.mount('#app')

import Tooltip from 'primevue/tooltip';
import * as Sentry from "@sentry/vue";

app.directive('tooltip', Tooltip);
