<template>
  <div id="app" class="vw-100 vh-100 d-flex flex-row">
    <main-menu v-if="is_logged_in"></main-menu>

    <div :class="{'flex-grow-1': !['/', '/login'].includes($route.path)}" class="bg-light" style="max-height: 100%; overflow-y: auto">
      <RouterView/>
    </div>

    <div v-if="$route.path === '/'" class="login-bg position-relative d-none d-sm-block">
      <div class="d-flex justify-end align-items-center h-full pe-3">
        <img src="https://s3.us-central-1.wasabisys.com/tf-public-dl/sara/assets/login_side_image"
             class="rounded-xl h-[95vh] object-cover">
      </div>
    </div>

    <flash-alert></flash-alert>
  </div>
</template>

<script>
import {mapState} from "pinia";
import {useUserStore} from './stores/user'
import MainMenu from "./components/home/MainMenu.vue";

export default {
  name: 'App',
  components: {
    MainMenu,
  },
  computed: {
    ...mapState(useUserStore, ['is_logged_in'])
  },
  watch: {
    $route(to, from) {
      //always clear the pusher channels when we change route
      for (let channel of this.$pusher.allChannels()) {
        channel.unsubscribe();
        channel.unbind_all();
      }
    }
  },
}
</script>
<style>
.login-bg {
  flex: 1;
  background: url("../src/assets/esta.png") center;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

</style>
