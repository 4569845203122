import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from "../views/Login.vue";
import axios from "axios";
import {useUserStore} from "../stores/user";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomeView
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: '/recuperarContrasena',
      name: 'RecuperarContrasena',
      component: () => import('../views/RecuperarContrasena.vue'),
      meta: {
        guest: true
      }
    },
    {
      path: '/restablecerContrasena',
      name: 'RestablecerContrasena',
      component: () => import('../views/RestablecerContrasena.vue'),
      meta: {
        guest: true
      }
    },

    // Configuraciones
    {
      path: '/mi_cuenta',
      name: 'MiCuenta',
      component: () => import('../views/cuenta/Index.vue'),
    },
    {
      path: '/configuraciones',
      name: 'ConfiguracionesListar',
      component: () => import('../views/configuraciones/Lista.vue')
    },
    // Catálogos

    // Cajas
    {
      path: '/cajas',
      name: 'CajasListar',
      component: () => import('../views/cajas/Lista.vue')
    },
    {
      path: '/cajas/create',
      name: 'CajasCrear',
      component: () => import('../views/cajas/CrearEditar.vue')
    },
    {
      path: '/cajas/edit/:caja_id',
      name: 'CajasEditar',
      props: true,
      component: () => import('../views/cajas/CrearEditar.vue')
    },
    {
      path: '/movimientosCaja',
      name: 'ListaMovimientosCaja',
      component: () => import('../views/cajas/ListaMovimientosCaja.vue')
    },

    // Categorías
    {
      path: '/categorias',
      name: 'ListaCategorias',
      component: () => import('../views/categorias/Lista.vue')
    },
    {
      path: '/categorias/create',
      name: 'CrearCategoria',
      component: () => import('../views/categorias/CrearEditar.vue')
    },
    {
      path: '/categorias/edit/:categoria_id',
      name: 'EditarCategoria',
      props: true,
      component: () => import('../views/categorias/CrearEditar.vue')
    },

    // Sucursales
    {
      path: '/sucursales',
      name: 'ListaSucursales',
      component: () => import('../views/sucursales/Lista.vue')
    },
    {
      path: '/sucursales/create',
      name: 'CrearSucursal',
      component: () => import('../views/sucursales/CrearEditar.vue')
    },
    {
      path: '/sucursales/edit/:sucursal_id',
      name: 'EditarSucursal',
      props: true,
      component: () => import('../views/sucursales/CrearEditar.vue')
    },

    //Formas de pago
    {
      path: '/formasPago',
      name: 'FormasPagosListar',
      component: () => import('../views/formasPago/Lista.vue')
    },
    {
      path: '/formasPago/create',
      name: 'FormasPagosCrear',
      component: () => import('../views/formasPago/CrearEditar.vue')
    },
    {
      path: '/formasPago/edit/:forma_pago_id',
      name: 'FormasPagosEditar',
      props: true,
      component: () => import('../views/formasPago/CrearEditar.vue')
    },

    // Clientes
    {
      path: '/clientes',
      name: 'ListaClientes',
      component: () => import('../views/clientes/Lista.vue')
    },
    {
      path: '/clientes/create',
      name: 'CrearCliente',
      component: () => import('../views/clientes/CrearEditar.vue')
    },
    {
      path: '/clientes/edit/:cliente_id',
      name: 'EditarCliente',
      props: true,
      component: () => import('../views/clientes/CrearEditar.vue')
    },
    {
      path: '/clientes/edit/:cliente_id/estadosCuenta',
      name: 'ClientesEstadosCuenta',
      props: true,
      component: () => import('../views/clientes/EstadosCuenta.vue')
    },
    {
      path: '/clientes/:cliente_id/facturacion',
      name: 'FacturacionCliente',
      props: true,
      component: () => import('../views/clientes/Facturacion.vue')
    },

    // Zonas
    {
      path: '/zonas',
      name: 'VistaZonas',
      component: () => import('../views/zonas/Vista.vue')
    },

    // Almacenes
    {
      path: '/almacenes',
      name: 'ListaAlmacenes',
      component: () => import('../views/almacenes/Lista.vue')
    },
    {
      path: '/almacenes/create',
      name: 'CrearAlmacen',
      component: () => import('../views/almacenes/CrearEditar.vue')
    },
    {
      path: '/almacenes/edit/:almacen_id',
      name: 'EditarAlmacen',
      props: true,
      component: () => import('../views/almacenes/CrearEditar.vue')
    },

    // Estaciones
    {
      path: '/estaciones',
      name: 'ListaEstaciones',
      component: () => import('../views/estaciones/Lista.vue')
    },
    {
      path: '/estaciones/create',
      name: 'CrearEstaciones',
      component: () => import('../views/estaciones/CrearEditar.vue')
    },
    {
      path: '/estaciones/edit/:estacion_id',
      name: 'EditarEstacion',
      props: true,
      component: () => import('../views/estaciones/CrearEditar.vue')
    },

    // Estaciones de validacion
    {
      path: '/estaciones_validacion',
      name: 'ListaEstacionesValidacion',
      component: () => import('../views/estaciones_validacion/Lista.vue')
    },
    {
      path: '/estaciones_validacion/create',
      name: 'CrearEstacionesValidacion',
      component: () => import('../views/estaciones_validacion/CrearEditar.vue')
    },
    {
      path: '/estaciones_validacion/edit/:estacion_id',
      name: 'EditarEstacionValidacion',
      props: true,
      component: () => import('../views/estaciones_validacion/CrearEditar.vue')
    },

    // Listas de precios
    {
      path: '/listasPrecios',
      name: 'ListasPreciosListar',
      component: () => import('../views/listasPrecios/Lista.vue')
    },
    {
      path: '/listasPrecios/create',
      name: 'ListasPreciosCrear',
      component: () => import('../views/listasPrecios/CrearEditar.vue')
    },
    {
      path: '/listasPrecios/edit/:lista_precios_id',
      name: 'ListasPreciosEditar',
      props: true,
      component: () => import('../views/listasPrecios/CrearEditar.vue')
    },

    // Motivos de devolución
    {
      path: '/motivosDevolucion',
      name: 'MotivosDevolucionListar',
      component: () => import('../views/motivosDevolucion/Lista.vue')
    },
    {
      path: '/motivosDevolucion/create',
      name: 'MotivosDevolucionCrear',
      component: () => import('../views/motivosDevolucion/CrearEditar.vue')
    },
    {
      path: '/motivosDevolucion/edit/:motivo_devolucion_id',
      name: 'MotivosDevolucionEditar',
      props: true,
      component: () => import('../views/motivosDevolucion/CrearEditar.vue')
    },

    // Productos
    {
      path: '/productos',
      name: 'ProductosListar',
      component: () => import('../views/productos/Lista.vue')
    },
    {
      path: '/productos/create',
      name: 'ProductosCrear',
      component: () => import('../views/productos/CrearEditar.vue')
    },
    {
      path: '/productos/edit/:producto_id',
      name: 'ProductosEditar',
      props: true,
      component: () => import('../views/productos/CrearEditar.vue')
    },

    // Unidades de entrega
    {
      path: '/unidadesEntrega',
      name: 'UnidadesEntregaListar',
      component: () => import('../views/unidadesEntrega/Lista.vue')
    },
    {
      path: '/unidadesEntrega/create',
      name: 'UnidadesEntregaCrear',
      component: () => import('../views/unidadesEntrega/CrearEditar.vue')
    },
    {
      path: '/unidadesEntrega/edit/:unidad_entrega_id',
      name: 'UnidadesEntregaEditar',
      props: true,
      component: () => import('../views/unidadesEntrega/CrearEditar.vue')
    },

    // Unidades de medidas
    {
      path: '/unidadesMedida',
      name: 'UnidadesMedidaListar',
      component: () => import('../views/unidadesMedida/Lista.vue')
    },
    {
      path: '/unidadesMedida/create',
      name: 'UnidadesMedidaCrear',
      component: () => import('../views/unidadesMedida/CrearEditar.vue')
    },
    {
      path: '/unidadesMedida/edit/:unidad_medida_id',
      name: 'UnidadesMedidaEditar',
      props: true,
      component: () => import('../views/unidadesMedida/CrearEditar.vue')
    },

    // Usuarios
    {
      path: '/usuarios',
      name: 'ListaUsuarios',
      component: () => import('../views/usuarios/Lista.vue')
    },
    {
      path: '/usuarios/create',
      name: 'CrearUsuario',
      component: () => import('../views/usuarios/CrearEditar.vue')
    },
    {
      path: '/usuarios/edit/:user_id',
      name: 'EditarUsuario',
      props: true,
      component: () => import('../views/usuarios/CrearEditar.vue')
    },

    // Tickets
    {
      path: '/tickets',
      name: 'ListaTickets',
      component: () => import('../views/tickets/Lista.vue')
    },
    {
      path: '/tickets/create',
      name: 'CrearTicket',
      component: () => import('../views/tickets/CrearEditar.vue')
    },
    {
      path: '/tickets/edit/:ticket_id',
      name: 'EditarTicket',
      props: true,
      component: () => import('../views/tickets/CrearEditar.vue')
    },

    // Acciones

    // Asociación de precios
    {
      path: '/productos/asociarListasPrecios/:producto_id',
      name: 'AsociarListasProducto',
      props: true,
      component: () => import('../views/listasPrecios/AsociarListas.vue')
    },
    {
      path: '/listasPrecios/asociarProductos/:lista_precios_id',
      name: 'AsociarProductosListas',
      props: true,
      component: () => import('../views/listasPrecios/AsociarProductos.vue')
    },

    // Pedidos
    {
      path: '/pedidos',
      name: 'PedidoView',
      component: () => import('../views/pedidos/Vista.vue')
    },
    {
      path: '/estatusPedidos',
      name: 'EstatusPedidosListar',
      component: () => import('../views/pedidos/Estatus.vue')
    },
    {
      path: '/estatusVentas',
      name: 'VentasListar',
      component: () => import('../views/ventas/Estatus.vue')
    },
    {
      path: '/reporteVentas',
      name: 'ReporteVentas',
      component: () => import('../views/ventas/reportes/ReporteVentas.vue')
    },
    {
      path: '/reportePedidos',
      name: 'ReportePedidos',
      component: () => import('../views/ventas/reportes/ReportePedidos.vue')
    },
    {
      path: '/reporteDevoluciones',
      name: 'ReporteDevoluciones',
      component: () => import('../views/ventas/reportes/ReporteDevoluciones.vue')
    },
    {
      path: '/reporteRepartidores',
      name: 'ReporteRepartidores',
      component: () => import('../views/ventas/reportes/ReporteRepartidores.vue')
    },
    {
      path: '/reporteSaldosClientes',
      name: 'ReporteSaldosClientes',
      component: () => import('../views/ventas/reportes/ReporteSaldosClientes.vue')
    },
    {
      path: '/reporteCancelaciones',
      name: 'ReporteCancelaciones',
      component: () => import('../views/ventas/reportes/ReporteCancelaciones.vue')
    },
    {
      path: '/reporteTrasladosDiferencias',
      name: 'ReporteTrasladosDiferencias',
      component: () => import('../views/ventas/reportes/ReporteTrasladosDiferencias.vue')
    },
    {
      path: '/indicadores',
      name: 'Indicadores',
      component: () => import('../views/ventas/reportes/Indicadores.vue')
    },
    {
      path: '/cobranzaPedidos',
      name: 'CobranzaPedidosListar',
      component: () => import('../views/pedidos/Cobranza.vue')
    },

    //Movimiento de almacenes
    {
      path: '/movimiento_almacen/:tipo',
      name: 'MovAlmacenesView',
      component: () => import('../views/movimientos_almacen/Vista.vue'),
      props: true
    },
    {
      path: '/movimiento_almacen',
      name: 'MovAlmacenesListar',
      component: () => import('../views/movimientos_almacen/Lista.vue'),
      props: true
    },
    {
      path: '/existencias',
      name: 'ExistenciasListar',
      component: () => import('../views/existencias/Lista.vue'),
      props: true
    },


    // Repartidores
    {
      path: '/repartidores',
      name: 'RepartidoresListar',
      component: () => import('../views/repartidores/Lista.vue')
    },
    {
      path: '/repartidores/:repartidor_id',
      name: 'RepartidoresDetalle',
      props: true,
      component: () => import('../views/repartidores/Detalle.vue')
    },

    // Reportes
    {
      path: '/monitorPedidos',
      name: 'MonitorPedidosListar',
      component: () => import('../views/pedidos/Monitor.vue')
    },
    {
      path: '/depositosGeneral',
      name: 'DepositosGeneral',
      component: () => import('../views/pedidos/DepositosGeneral.vue')
    },
    {
      path: '/monitorRastreo',
      name: 'MonitorRastreo',
      component: () => import('../views/unidadesEntrega/MonitorRastreo.vue')
    },

    // Promociones
    {
      path: '/promociones',
      name: 'ListaPromociones',
      component: () => import('../views/promociones/Lista.vue')
    },
    {
      path: '/promociones/create',
      name: 'CrearPromocion',
      component: () => import('../views/promociones/CrearEditar.vue')
    },
    {
      path: '/promociones/edit/:promocion_id',
      name: 'EditarPromocion',
      props: true,
      component: () => import('../views/promociones/CrearEditar.vue')
    },
    {
      path: '/catalogos',
      name: 'MainCatalogos',
      component: () => import('../components/catalogos/MainCatalogos.vue')
    },
    {
      path: '/reportes',
      name: 'ReportesMenu',
      component: () => import('../components/catalogos/ReportesMenu.vue')
    },
    //Proveedores
    {
      path: '/proveedores',
      name: 'ListaProveedores',
      component: () => import('../views/proveedores/Lista.vue')
    },
    {
      path: '/proveedores/create',
      name: 'CrearProveedor',
      component: () => import('../views/proveedores/CrearEditar.vue')
    },
    {
      path: '/proveedores/edit/:proveedor_id',
      name: 'EditarProveedor',
      props: true,
      component: () => import('../views/proveedores/CrearEditar.vue')
    },
    {
      path: '/proveedores/edit/:proveedor_id/estadosCuenta',
      name: 'ProveedoresEstadosCuenta',
      props: true,
      component: () => import('../views/proveedores/EstadosCuenta.vue')
    },

    //Cajas operación
    {
      path: '/cajasop',
      name: 'ListaCajasOperacion',
      component: () => import('../views/cajas/ListaOperacion.vue')
    },
    {
      path: '/cajasop/:caja_id/historial',
      name: 'HistorialCaja',
      component: () => import('../views/cajas/HistorialCaja.vue')
    },
    //Notificaciones
    {
      path: '/notificaciones_enviadas',
      name: 'ListaNotificaciones',
      component: () => import('../views/notificaciones/Lista.vue')
    },
    {
      path: '/reporteGeneralVentas',
      name: 'ReporteGeneralVentas',
      component: () => import('../views/ventas/reportes/ReporteGeneralVentas.vue')
    },
    {
      path: '/reporteVentasHora',
      name: 'ReporteVentasHora',
      component:()=> import('../views/ventas/reportes/ReporteVentasHora.vue')
    },
    {
      path: '/ReporteProductividadCarniceros',
      name: 'ReporteProductividadCarniceros',
      component: () => import('../views/ventas/reportes/ReporteProductividadColaborador.vue')
    },
    {
      path: '/transformacion',
      name: 'ProcesoTransformacion',
      component: () => import('../views/proceso_transformacion/index.vue')
    }, {
      path: '/transformacion/show/:transformacion_id',
      name: 'ProcesoTransformacionShow',
      props: true,
      component: () => import('../views/proceso_transformacion/index.vue')
    },
    {
      path: '/ReporteRazonTransformacion',
      name: 'ReporteRazonTransformacion',
      component: () => import('../views/movimientos_almacen/reportes/ReporteRazonTransformacion.vue')
    },
    // Monitores
    {
      path: '/ContenidoMonitores',
      name: 'ContenidoMonitoresLista',
      component: () => import('../views/contenido/monitores/MonitoresList.vue')
    },
    {
      path: '/ContenidoMonitores/create',
      name: 'ContenidoMonitoresCrear',
      component: () => import('../views/contenido/monitores/CreateEditMonitores.vue')
    },
    {
      path: '/ContenidoMonitores/edit/:monitor_id',
      name: 'ContenidoMonitoresEditar',
      component: () => import('../views/contenido/monitores/CreateEditMonitores.vue'),
      props: true
    },
    // Repositorio
    {
      path: '/ContenidoRepositorio',
      name: 'ContenidoRepositorioLista',
      component: () => import('../views/contenido/repositorio/RepositorioLista.vue')
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const store = useUserStore();

  async function tryLogin() {
    try {
      let res = await axios.get('/api/user');
      store.data = res?.data?.data?.user;
      store.is_logged_in = true;
      store.permisos = res?.data?.data?.permisos;
      store.configuraciones = res?.data?.data?.configuraciones;
      return true;
    } catch (err) {
      return false;
    }
  }

  if (to.matched.some(record => record.meta.guest)) {
    if (store.is_logged_in && store.data != null) {
      next({name: 'Home'})
    } else {
      if (await tryLogin()) {
        next({name: 'Home'});
      } else {
        next();
      }
    }
  } else {
    if (store.is_logged_in && store.data != null) {
      next();
    } else {
      if (await tryLogin()) {
        next()
      } else {
        next({
          path: '/login',
          params: {nextUrl: to.fullPath}
        });
      }
    }
  }
});

export default router
